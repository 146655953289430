import React from 'react';
import s from './CategoryCard.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
    category: string;
    image: string;
    url: string;
    description?: string;
}

const CategoryCard = ({ image, category, url, description }: Props) => {
    const navigate = useNavigate();

    const handleClick = () => navigate(url);

    return (
        <div className={s.card} onClick={handleClick}>
            <div className={s.imageContainer}>
                <img className={s.image} src={image} alt='category' />
            </div>
            <div className={s.content}>
                <h3 className={s.title}>{category}</h3>
                {description && (
                    <span className={s.description}>{description}</span>
                )}
            </div>
        </div>
    );
};

export default CategoryCard;
