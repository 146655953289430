import React from 'react';
import s from './MobileMenu.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

interface Props {
    isActive: boolean;
    onClose: () => void;
}

const MobileMenu = ({ isActive, onClose }: Props) => {
    return (
        <>
            <div
                className={isActive ? [s.darkBackground, s.active].join(' ') : s.darkBackground}
                onClick={onClose}
            ></div>

            <div className={isActive ? [s.mobileMenu, s.active].join(' ') : s.mobileMenu}>
                <div className={s.mobileMenuTop}>
                    <h3 className={s.mobileMenuTitle}>MENU </h3>
                    <FontAwesomeIcon icon={faTimes} className={s.mobileClose} onClick={onClose} />
                </div>

                <ul className={s.mobileLinksList}>
                    <Link to='/produkty' className={s.mobileLink} onClick={onClose}>
                        Produkty
                    </Link>
                    {/* <Link
                        to='/obchod'
                        className={s.mobileLink}
                        onClick={onClose}
                    >
                        Obchod
                    </Link> */}
                    <Link to='/servis' className={s.mobileLink} onClick={onClose}>
                        Servis
                    </Link>
                    <Link to='/aktuality' className={s.mobileLink} onClick={onClose}>
                        Aktuality
                    </Link>
                    <Link to='/o-nas' className={s.mobileLink} onClick={onClose}>
                        O nás
                    </Link>
                    <Link to='/kontakt' className={s.mobileLink} onClick={onClose}>
                        Kontakt
                    </Link>
                </ul>

                <div className={s.mobileSocials}>
                    <FontAwesomeIcon icon={faFacebook} className={s.socialIcon} />
                    <FontAwesomeIcon icon={faInstagram} className={s.socialIcon} />
                    <FontAwesomeIcon icon={faYoutube} className={s.socialIcon} />
                </div>
            </div>
        </>
    );
};

export default MobileMenu;
