import { Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ISpecialOffer } from 'types/types';
import { addStrapiUrl } from 'utils/strapiUtils';
import s from './SpecialOfferCard.module.scss';

type Props = {
    product: ISpecialOffer;
};

const SpecialOfferCard = ({ product }: Props) => {
    const CATEGORY_URL = product.attributes.subcategory.data.attributes.category.data.attributes.url;
    const SUBCATEGORY_URL = product.attributes.subcategory.data.attributes.url;

    const PRODUCT_URL = `../produkty/${CATEGORY_URL}/${SUBCATEGORY_URL}/${product.attributes.url}`;

    return (
        <li className={s.cardContainer}>
            <div className={s.imageContainer}>
                <img
                    className={s.image}
                    src={addStrapiUrl(product.attributes.images.data[0].attributes.url)}
                    alt={addStrapiUrl(product.attributes.images.data[0].attributes.alternativeText)}
                />
            </div>
            <div className={s.content}>
                <h3 className={s.name}>{product.attributes.name}</h3>
                <div className={s.contentBottom}>
                    <div className={s.prices}>
                        <p className={s.priceOriginal}>€{product.attributes.price_normal}</p>
                        <p className={s.priceNew}>€{product.attributes.price_new} </p>
                        <span className={s.noVAT}>bez DPH</span>
                    </div>
                    <Link to={PRODUCT_URL}>
                        <Button variant='contained' sx={{ width: '100%' }}>
                            Podrobnosti
                        </Button>
                    </Link>
                </div>
            </div>
        </li>
    );
};

export default SpecialOfferCard;
