import React from 'react';
import Layout from 'components/Layout/Layout';
import OurOffer from 'pages/HomePage/OurOfferSection/OurOfferSection';
import ContactSection from 'pages/HomePage/ContactSection/ContactSection';
import RepresentationSection from 'pages/HomePage/RepresentationSection/RepresentationSection';
import LandingSection from 'pages/HomePage/LandingSection/LandingSection';

interface Props {}

const HomePage = (props: Props) => {
    return (
        <Layout>
            <LandingSection />
            <OurOffer />
            <ContactSection />
            <RepresentationSection />
        </Layout>
    );
};

export default HomePage;
