import React from 'react';
import './Layout.scss';
import Header from 'components/Layout/Header/Header';
import Navbar from 'components/Layout/Navbar/Navbar';
import Footer from 'components/Layout/Footer/Footer';

interface Props {
    children?: React.ReactNode;
}

const Layout = (props: Props) => {
    return (
        <div className='layout'>
            <Header />
            <Navbar />
            <main className='layout__content'>{props.children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
