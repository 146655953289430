import React from 'react';
import s from './Header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import SearchBar from './SearchBar/SearchBar';

interface Props {}

const Header = (props: Props) => {
    return (
        <header className={s.header}>
            <div className={s.contacts}>
                <div className={s.contactContainer}>
                    <FontAwesomeIcon icon={faPhone} className={s.icon} />
                    <a href='tel:+421911432093' className={s.phone}>
                        +421 911 432 093
                    </a>
                </div>
                <div className={s.contactContainer}>
                    <FontAwesomeIcon icon={faEnvelope} className={s.icon} />
                    <a href='mailto:office@optical.sk ' className={s.email}>
                        office@optical.sk
                    </a>
                </div>
            </div>

            <div className={s.rightContainer}>
                <SearchBar />
                <SocialMediaLinks color='black' />
            </div>
        </header>
    );
};

export default Header;
