import React from 'react';
import s from './NotFoundPage.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {}

const NotFound = (props: Props) => {
    const navigate = useNavigate();

    const handleButtonClick = (): void => {
        navigate(-1);
    };

    return (
        <div className={s.container}>
            <div className={s.content}>
                <h1 className={s.title}>404 Stránka nenájdená</h1>
                <Button
                    size='large'
                    variant='contained'
                    onClick={handleButtonClick}
                >
                    Vráť sa
                </Button>
            </div>
        </div>
    );
};

export default NotFound;
