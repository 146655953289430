import { url } from 'inspector';
import React, { ReactNode } from 'react';
import s from './SubPage.module.scss';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

type Props = {
    children: ReactNode;
    currentPageName?: string;
    showBreadcrumbs?: boolean;
    title?: string;
    description?: string;
    actions?: ReactNode;
    fullWidth?: boolean;
    image?: string;
};

const SubPage = ({
    children,
    title,
    description,
    actions,
    image,
    fullWidth = false,
    currentPageName,
    showBreadcrumbs = true,
}: Props) => {
    const imageStyle = {
        backgroundImage: image && `url("${image}")`,
    };

    const noImageStyle = {
        background: 'linear-gradient(to right, #0072bc, #3f9add, #80c4ff)',
    };

    const style = image ? imageStyle : noImageStyle;

    return (
        <div className={s.container}>
            {title && (
                <div className={s.headerBgImage} style={style}>
                    <div className={s.header}>
                        <div className={s.headerContent}>
                            <div className={s.textContainer}>
                                <h1 className={s.title}>{title}</h1>
                                {description && <p className={s.description}>{description}</p>}
                            </div>
                            {actions}
                        </div>
                    </div>
                </div>
            )}
            <div className={`${s.content} ${fullWidth && s.fullWidth}`}>
                {showBreadcrumbs && <Breadcrumbs currentPageName={currentPageName} />}
                {children}
            </div>
        </div>
    );
};

export default SubPage;
