import React, { useEffect, useState } from 'react';
import s from './SearchBar.module.scss';
import { gql, useQuery } from '@apollo/client';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { ISearchedProduct } from 'types/types';
import { addStrapiUrl } from 'utils/strapiUtils';

type Props = {};

const GET_SEARCHED_PRODUCTS = gql`
    query GetProductsByName($name: String!) {
        products(
            pagination: { limit: 10 }
            filters: { or: [{ name: { containsi: $name } }, { subcategory: { name: { containsi: $name } } }] }
        ) {
            data {
                id
                attributes {
                    name
                    url
                    images {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                    subcategory {
                        data {
                            attributes {
                                url
                                category {
                                    data {
                                        attributes {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const SearchBar = (props: Props) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSearchBarActive, setIsSearchBarActive] = useState<boolean>(false);
    const [searchedProducts, setSearchedProducts] = useState<ISearchedProduct[] | null>(null);
    const { refetch } = useQuery(GET_SEARCHED_PRODUCTS, {
        variables: { name: searchValue },
        skip: true,
    });

    const handleSearchValueChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onFocusHandler = (): void => setIsSearchBarActive(true);

    const onBlurHandler = (e: React.FocusEvent<HTMLDivElement, Element>): void => {
        if (e.currentTarget.contains(e.relatedTarget)) return;

        setIsSearchBarActive(false);
        setSearchedProducts(null);
        setSearchValue('');
    };

    useEffect(() => {
        const refetchProducts = async (): Promise<void> => {
            if (searchValue === '') {
                setSearchedProducts(null);
                return;
            }

            const response = await refetch({ name: searchValue });
            setSearchedProducts(response?.data?.products?.data);
        };

        refetchProducts();
    }, [searchValue, refetch]);

    return (
        <div className={s.container} onFocus={onFocusHandler} onBlur={onBlurHandler}>
            <TextField
                className={s.input}
                placeholder='Zadejte hledaný výrobek...'
                autoComplete='off'
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchValueChange(e)}
                InputProps={{
                    startAdornment: <FontAwesomeIcon className={s.icon} icon={faMagnifyingGlass} />,
                }}
                variant='standard'
                size='small'
                fullWidth
            />

            {isSearchBarActive && searchedProducts && (
                <ul className={s.searchList} onMouseOver={() => setIsSearchBarActive(true)}>
                    {searchedProducts.map((product) => (
                        <li className={s.searchListItem} key={product.id}>
                            <Link
                                className={s.searchListItemLink}
                                to={`/produkty/${product.attributes.subcategory.data.attributes.category.data.attributes.url}/${product.attributes.subcategory.data.attributes.url}/${product.attributes.url}`}
                            >
                                <img
                                    className={s.image}
                                    src={addStrapiUrl(product.attributes.images.data[0].attributes.url)}
                                    alt={addStrapiUrl(product.attributes.images.data[0].attributes.alternativeText)}
                                />
                                {product.attributes.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBar;
