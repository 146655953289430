import React, { useMemo } from 'react';
import { Link as MuiLink, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';

interface Props {
    currentPageName?: string | null;
}

const Breadcrumbs = ({ currentPageName = null }: Props) => {
    const location = useLocation();

    const crumbs = useMemo(() => {
        const array: string[] = location.pathname.split('/').slice(1);

        return array.map((crumb) => crumb.charAt(0).toUpperCase() + crumb.slice(1));
    }, [location]);

    const getReturnHref: (crumbName: string) => string = (crumbName: string) => {
        let href: string = '..';

        for (const crumb of crumbs.slice().reverse().slice(1)) {
            if (crumbName === crumb) return href;

            href += '/..';
        }

        return href;
    };

    return (
        <MuiBreadcrumbs>
            <MuiLink component={Link} to='/' underline='hover' color='inherit'>
                Home
            </MuiLink>

            {crumbs.slice(0, -1).map((crumb) => (
                <MuiLink component={Link} to={getReturnHref(crumb)} underline='hover' color='inherit' key={crumb}>
                    {crumb.replace('-', ' ')}
                </MuiLink>
            ))}

            <Typography color='text.primary'>
                {currentPageName || crumbs[crumbs.length - 1].replace('-', ' ')}
            </Typography>
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
