import React from 'react';
import s from './Article.module.scss';
import { IServiceArticle } from 'types/types';
import SubPage from 'components/SubPage/SubPage';
import dayjs from 'dayjs';
import { Button, Chip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import Loading from 'components/Loading/Loading';
import RichTextDisplay from 'components/RichTextDisplay/RichTextDisplay';

interface Props {}

const GET_SERVICE_ARTICLE = gql`
    query GET_SERVICE_ARTICLES($id: ID!) {
        serviceArticle(id: $id) {
            data {
                id
                attributes {
                    title
                    description
                    createdAt
                    article
                    image {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Article = (props: Props) => {
    const { id } = useParams();
    const { loading, error, data } = useQuery(GET_SERVICE_ARTICLE, {
        variables: { id: id },
    });
    const article: IServiceArticle = data?.serviceArticle?.data;

    if (loading) return <Loading />;

    if (error || (!loading && !article)) return <NotFound />;

    return (
        <SubPage currentPageName={article.attributes.title}>
            <div className={s.container}>
                <div className={s.topContainer}>
                    <h1 className={s.title}>{article.attributes.title}</h1>
                    <div className={s.date}>
                        <Chip
                            label={dayjs(article.attributes.createdAt).format('DD.MM.YYYY')}
                            icon={<FontAwesomeIcon icon={faCalendarDays} size='lg' />}
                            sx={{ padding: '.5rem' }}
                        />
                    </div>
                </div>
                <RichTextDisplay html={article.attributes.article} />
            </div>
        </SubPage>
    );
};

export default Article;
