import React from 'react';
import s from './RepresentationSection.module.scss';
import Section from 'components/Section/Section';
import nidekLogo from 'assets/representation-logos/nidek-logo.png';
import csoLogo from 'assets/representation-logos/cso-logo.png';
import takagiLogo from 'assets/representation-logos/takagi-logo.png';
import polandOpticalLogo from 'assets/representation-logos/poland-optical-logo.png';

type Props = {};

const RepresentationSection = (props: Props) => {
    return (
        <Section className={s.section}>
            <div className={s.container}>
                <a href='https://www.polandoptical.pl/' target='_blank' rel='noreferrer' className={s.logoContainer}>
                    <img className={s.logo} src={polandOpticalLogo} alt='poland optical logo' />
                </a>

                <a href='https://www.nidek-intl.com/' target='_blank' rel='noreferrer' className={s.logoContainer}>
                    <img className={s.logo} src={nidekLogo} alt='nidek logo' />
                </a>

                <a href='https://www.csoitalia.it/en' target='_blank' rel='noreferrer' className={s.logoContainer}>
                    <img className={s.logo} src={csoLogo} alt='cso logo' />
                </a>

                <a href='https://www.takagieurope.com/' target='_blank' rel='noreferrer' className={s.logoContainer}>
                    <img className={s.logo} src={takagiLogo} alt='takagi logo' />
                </a>
            </div>
        </Section>
    );
};

export default RepresentationSection;
