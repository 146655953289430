import React from 'react';
import s from './ContactSection.module.scss';
import BeanButton from 'components/BeanButton/BeanButton';

type Props = {};

const ContactSection = (props: Props) => {
    return (
        <section className={s.contactSection}>
            <h2 className={s.title}>Buďme v kontakte!</h2>
            <BeanButton text='Kontaktuj nás' link='/kontakt' color='white' variant='outlined' />
            {/* <Button variant='contained' size='large' className={s.button}>
                    Kontaktuj nás
                </Button> */}
        </section>
    );
};

export default ContactSection;
