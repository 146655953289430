import React from 'react';
import { IServiceArticle } from 'types/types';
import s from './ServiceArticlesList.module.scss';
import ServiceArticlesItem from './ServiceArticlesItem/ServiceArticlesItem';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
    articles: IServiceArticle[];
    showBlogButton?: boolean;
}

const ServiceArticlesList = ({ articles, showBlogButton = false }: Props) => {
    return (
        <div className={s.container}>
            <div className={s.heading}>Servisné novinky</div>
            <ul className={s.list}>
                {articles.length === 0 && <li className={s.noNews}>Žiadne novinky</li>}
                {articles.map((article: IServiceArticle) => (
                    <ServiceArticlesItem article={article} key={article.id} />
                ))}
            </ul>
        </div>
    );
};

export default ServiceArticlesList;
