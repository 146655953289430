import React, { useState } from 'react';
import s from './ServicePage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Button, Divider, Link } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlog, faNewspaper, faWrench } from '@fortawesome/free-solid-svg-icons';
import ServiceRequestForm from './ServiceRequestForm/ServiceRequestForm';
import ServiceArticlesList from './ServiceArticlesList/ServiceArticlesList';
import { gql, useQuery } from '@apollo/client';
import { IFAQ, IServiceArticle } from 'types/types';
import FAQ from './FAQ/FAQ';
import Loading from 'components/Loading/Loading';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AllServiceArticles from './AllServiceArticles/AllServiceArticles';
import Article from './Article/Article';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import backgroundImage from 'assets/subpage-images/service.png';
import BeanButton from 'components/BeanButton/BeanButton';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

interface Props {}

const GET_FAQ = gql`
    query GET_FAQ {
        faqs(pagination: { limit: -1 }) {
            data {
                id
                attributes {
                    question
                    answer
                }
            }
        }
    }
`;

const GET_SERVICE_ARTICLES = gql`
    query GET_SERVICE_ARTICLES {
        serviceArticles(pagination: { limit: 10 }, sort: "createdAt:desc") {
            data {
                id
                attributes {
                    title
                    description
                    createdAt
                    # article
                    image {
                        data {
                            id
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const ServicePage = (props: Props) => {
    const [isServiceRequestOpen, setIsServiceRequestOpen] = useState(false);

    const navigate = useNavigate();

    const { loading: loadingFAQ, error: errorFAQ, data: dataQuestions } = useQuery(GET_FAQ);
    const questions: IFAQ[] = dataQuestions?.faqs?.data;

    const { loading: loadingArticles, error: errorArticles, data: dataArticles } = useQuery(GET_SERVICE_ARTICLES);
    const articles: IServiceArticle[] = dataArticles?.serviceArticles?.data;

    return (
        <Layout>
            <Routes>
                <Route
                    index
                    element={
                        <SubPage
                            title={'Servis'}
                            description={'Autorizovaný servis zariadení Nidek, CSO'}
                            image={backgroundImage}
                            actions={
                                <div className={s.buttonsContainer}>
                                    <BeanButton
                                        color='black'
                                        text='Servisná požiadavka'
                                        onClick={() => setIsServiceRequestOpen(true)}
                                        iconEnd={<FontAwesomeIcon icon={faWrench} size='lg' />}
                                    />
                                </div>
                            }
                        >
                            {loadingFAQ && loadingArticles && <Loading />}

                            {!loadingFAQ && !loadingArticles && (
                                <div className={s.container}>
                                    <div className={s.mainContent}>
                                        <div className={s.heading}>O servise</div>
                                        <div className={s.textContent}>
                                            <p className={s.secondaryText}>
                                                Ponuka spoločnosti OPTICAL Slovakia nie je len vynikajúcim prístrojovým
                                                vybavením, optickými alebo oftalmologickými zariadeniami, ako aj
                                                výrobkami pre oftalmologické pracoviská. Naša spoločnosť poskytuje
                                                profesionálne servisné služby. O aké služby sa jedná?
                                            </p>

                                            <h2 className={s.subtitle}>Autorizovaný servis zariadení Nidek, CSO</h2>

                                            <p className={s.secondaryText}>
                                                OPTICAL Slovakial je predovšetkým autorizovaný servis oftalmologických a
                                                optických zariadení od spoločností <b>Nidek</b> a <b>CSO</b>, teda
                                                zariadení najčastejšie vybraných odborníkmi používanými na diagnostiku a
                                                liečbu rôznych typov očných vád a ochorení. Naša spoločnosť má už
                                                dlhoročné skúsenosti s poskytovaním tohto typu služieb, takže nám môžete
                                                plne dôverovať! Sme schopní opraviť mnoho rôznych typov porúch. V
                                                prípade akýchkoľvek problémov je potrebné nás kontaktovať, aby sme
                                                dohodli dátum servisu. Prídeme, skontrolujeme, posúdíme či je možné
                                                poruchu okamžite opraviť na mieste, alebo či bude potrebné vykonať
                                                komplikovanejšie práce alebo dokonca odobrať poškodené zariadenie a
                                                vykonať opravy v servise. Disponujeme vysoko kvalifikovanými technikmi,
                                                roky pôsobiacich v oblasti optiky a oftalmológie.
                                            </p>

                                            <p className={s.secondaryText}>
                                                Vďaka dlhoročnej spolupráci s výrobcami zariadení
                                                <b> Nidek</b> a <b>CSO sa</b> snažíme vyriešiť každý problém. Vykonávame
                                                nielen opravy poškodených oftalmologických či optických zariadení, ale
                                                aj technické kontroly.
                                            </p>

                                            <h2 className={s.subtitle}>Aktualizácie softvéru pre zariadenia Nidek</h2>

                                            <p className={s.secondaryText}>
                                                Okrem servisných služieb poskytujeme aj služby spočívajúce vo vykonávaní
                                                programovacích aktualizácií optických zariadení japonskej spoločnosti
                                                Nidek. Dlhoročná spolupráca s týmto svetoznámym výrobcom znamená, že
                                                naši zástupcovia môžu ľahko pomôcť našim zákazníkom stiahnuť a
                                                nainštalovať najnovší softvér, ktorý ešte viac zvýši možnosti použitého
                                                zariadenia.
                                            </p>
                                        </div>
                                    </div>
                                    {articles && <ServiceArticlesList articles={articles} showBlogButton />}
                                    {/* {questions && <FAQ questions={questions} />} */}
                                </div>
                            )}

                            <ServiceRequestForm
                                isOpen={isServiceRequestOpen}
                                onClose={() => setIsServiceRequestOpen(false)}
                            />
                        </SubPage>
                    }
                />
                <Route path=':id' element={<Article />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Layout>
    );
};

export default ServicePage;
