import React from 'react';
import s from './DownloadsModal.module.scss';
import { Modal } from '@mui/material';
import { IProductDownloadFiles } from 'types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { addStrapiUrl } from 'utils/strapiUtils';

interface Props {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    downloadFiles: IProductDownloadFiles;
}

const DownloadsModal = ({ isOpen, onClose, downloadFiles }: Props) => {
    const handleFileDownload = (fileUrl: string) => {
        window.open(addStrapiUrl(fileUrl));
    };

    return (
        <Modal className={s.modal} open={isOpen} onClose={onClose}>
            <div className={s.container}>
                <h5 className={s.title}>Súbory na stiahnutie</h5>
                <ul className={s.filesList}>
                    {downloadFiles.data.map((file) => (
                        <li className={s.file} onClick={() => handleFileDownload(file.attributes.url)} key={file.id}>
                            <FontAwesomeIcon className={s.fileIcon} icon={faFile} size={'2x'} />
                            <span className={s.fileCaption}>{file.attributes.caption}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </Modal>
    );
};

export default DownloadsModal;
