import React, { useState } from 'react';
import s from './AskForPriceForm.module.scss';
import Modal from '@mui/material/Modal';
import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { Box } from '@mui/system';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    productName: string;
};

const URL =
    process.env.NODE_ENV === 'production'
        ? `${process.env.API_URL}/product/ask-for-price`
        : 'http://localhost:1337/api/product/ask-for-price';

const AskForPriceForm = ({ isOpen, onClose, productName }: Props) => {
    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [company, setCompany] = useState<string>('');
    const [details, setDetails] = useState<string>('');
    const [selectedRegion, setSelectedRegion] = useState<string>('');

    const [alertMessage, setAlertMessage] = useState<string>('Dopyt odoslaný');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error'>('success');
    const [isAlertActive, setIsAlertActive] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const body = {
            name,
            surname,
            phone,
            email,
            company,
            region: selectedRegion,
            details,
            productName,
            productLink: window.location.href,
        };

        try {
            onClose();
            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.status === 200) {
                setAlertMessage('Dopyt odoslaný');
                setAlertSeverity('success');
            } else {
                setAlertMessage('Dopyt sa nepodarilo odoslať');
                setAlertSeverity('error');
            }
        } catch (error) {
            console.error(error);
            setAlertMessage('Dopyt sa nepodarilo odoslať');
            setAlertSeverity('error');
        } finally {
            setIsAlertActive(true);
            setTimeout((): void => setIsAlertActive(false), 5000);
            resetForm();
        }
    };

    const handleFormClose = (): void => {
        onClose();
        resetForm();
    };

    const resetForm = (): void => {
        setName('');
        setSurname('');
        setPhone('');
        setEmail('');
        setCompany('');
        setDetails('');
        setSelectedRegion('');
    };

    return (
        <>
            <Modal className={s.modal} open={isOpen} onClose={handleFormClose}>
                <div className={s.formContainer}>
                    <h5 className={s.title}>Spýtajte sa na cenu</h5>

                    <Box component='form' className={s.form} onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
                        <TextField
                            id='name'
                            label='Meno'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />

                        <TextField
                            id='surname'
                            label='Priezvisko'
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            required
                        />

                        <TextField
                            id='email'
                            label='E-mail'
                            type={'email'}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />

                        <TextField
                            id='phone'
                            label='Telefónne číslo'
                            inputProps={{ inputMode: 'tel', pattern: '\\d{9,}' }}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />

                        <TextField
                            id='company'
                            label='Firma'
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            required
                        />

                        <FormControl variant='outlined' className={s.regionInputContainer} required>
                            <InputLabel id='region-label'>Kraj</InputLabel>

                            <Select
                                labelId='region-label'
                                id='region'
                                label='Kraj'
                                variant='outlined'
                                value={selectedRegion}
                                onChange={(e) => setSelectedRegion(e.target.value)}
                            >
                                <MenuItem value='bratyslavsky'>Bratyslavsky</MenuItem>
                                <MenuItem value='banskobystrický'>Banskobystrický</MenuItem>
                                <MenuItem value='nitriansky'>Nitriansky</MenuItem>
                                <MenuItem value='Prešovský'>Prešovský</MenuItem>
                                <MenuItem value='Trenčiansky'>Trenčiansky</MenuItem>
                                <MenuItem value='Trnavský'>Trnavský</MenuItem>
                                <MenuItem value='Žilinský'>Žilinský</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            id='details'
                            className={s.detailsInput}
                            multiline
                            minRows={3}
                            maxRows={6}
                            label='Podrobnosti o dopyte'
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                        />

                        <FormControlLabel
                            className={s.checkboxLabel}
                            control={<Checkbox required />}
                            label='Souhlasím se zpracováním mých osobních údajů pro marketingové účely firmou CS Optical a znám svá práva vyplývající ze zákona na ochranu osobních údajů.*'
                        />

                        <Button className={s.submitButton} type={'submit'} variant='contained'>
                            Odoslať
                        </Button>
                        <Button
                            className={s.submitButton}
                            color='secondary'
                            variant='outlined'
                            onClick={handleFormClose}
                        >
                            Zrušiť
                        </Button>
                    </Box>
                </div>
            </Modal>

            {isAlertActive && (
                <div className={s.alert}>
                    <Alert variant='filled' severity={alertSeverity} onClose={() => setIsAlertActive(false)}>
                        {alertMessage}
                    </Alert>
                </div>
            )}
        </>
    );
};

export default AskForPriceForm;
