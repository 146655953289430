import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import React from 'react';
import image from 'assets/offer-images/offer-svg/financing.svg';
import s from './FinancePage.module.scss';

type Props = {};

const FinancePage = (props: Props) => {
    return (
        <Layout>
            <SubPage title='Financovanie'>
                <div className={s.container}>
                    <p className={s.text}>
                        Pri predaji našich zariadení, vieme poskytnúť našim klientom možnosť financovania v rámci
                        spoločnosti Optical Slovakia s.r.o. bez akéhokoľvek zvýšenia dohodnutej konečnej sumy. Pre
                        podrobnejšie informácie volajte na telefónne číslo <b>00421911 432 093</b>.
                    </p>
                    <img className={s.image} src={image} alt='financovanie' />
                </div>
            </SubPage>
        </Layout>
    );
};

export default FinancePage;
