import React from 'react';
import s from './OurOfferSection.module.scss';
import Section from 'components/Section/Section';
import OfferCard from './OfferCard/OfferCard';

import financeImage from 'assets/offer-images/offer-svg/financing.svg';
import discountsImage from 'assets/offer-images/offer-svg/discounts.svg';
import eventsImage from 'assets/offer-images/offer-svg/events.svg';

interface Props {}

const OurOffer = (props: Props) => {
    return (
        <Section>
            <div className={s.container}>
                <OfferCard
                    image={eventsImage}
                    imageAlt={'Aktuality'}
                    title='Aktuality'
                    link={'/aktuality'}
                    borderColor='light'
                    borderPostion='left'
                />

                <OfferCard
                    image={financeImage}
                    imageAlt={'Financovanie'}
                    title='Financovanie'
                    link={'/financovanie'}
                    borderColor='dark'
                    borderPostion='center'
                />

                <OfferCard
                    image={discountsImage}
                    imageAlt={'Propagačné akcie'}
                    title='Propagačné akcie'
                    link={'/propagacne-akcie'}
                    borderColor='light'
                    borderPostion='right'
                />
            </div>
        </Section>
    );
};

export default OurOffer;
