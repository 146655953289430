import React, { ReactElement } from 'react';
import s from './BeanButton.module.scss';
import { Link } from 'react-router-dom';

interface Props {
    text: string;
    link?: string;
    onClick?: () => void;
    variant?: 'contained' | 'outlined';
    color?: 'blue-gradient' | 'white' | 'blue' | 'black';
    iconStart?: ReactElement;
    iconEnd?: ReactElement;
    fullWidth?: boolean;
    disabled?: boolean;
}

const BeanButton = ({
    text,
    link,
    onClick,
    variant = 'contained',
    color = 'blue',
    iconStart,
    iconEnd,
    fullWidth = false,
    disabled = false,
}: Props) => {
    if (link)
        return (
            <Link
                to={link}
                className={`${s.button} ${s[variant] ?? ''} ${s[color] ?? ''} ${fullWidth && s.fullWidth}`}
                onClick={onClick}
            >
                {iconStart}
                {text}
                {iconEnd}
            </Link>
        );

    return (
        <button
            className={`${s.button} ${s[variant] ?? ''} ${s[color] ?? ''} ${fullWidth && s.fullWidth} ${
                disabled ? s.disabled : ''
            }`}
            onClick={onClick}
            disabled={disabled}
        >
            {iconStart}
            {text}
            {iconEnd}
        </button>
    );
};

export default BeanButton;
