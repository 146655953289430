import React from 'react';
import s from './ProductCard.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
    title: string;
    description?: string;
    url: string;
    image: string;
    isOfferNew?: boolean;
}

const ProductCard = ({ title, description, url, image, isOfferNew = false }: Props) => {
    const navigate = useNavigate();

    const handleClick = (): void => {
        navigate(url);
    };

    return (
        <li className={s.cardContainer} onClick={handleClick}>
            <div className={s.imageContainer}>
                <img className={s.image} src={image} alt='category' />
            </div>
            {isOfferNew && <div className={s.newOffer}>Novinka</div>}
            <div className={s.content}>
                <h3 className={s.title}>{title}</h3>
                {description && <span className={s.description}>{description}</span>}
            </div>
        </li>
    );
};

export default ProductCard;
