import React from 'react';
import s from './AboutUsPage.module.scss';
import Layout from 'components/Layout/Layout';
import SubPage from 'components/SubPage/SubPage';
import { Link } from 'react-router-dom';

type Props = {};

const AboutUsPage = (props: Props) => {
    return (
        <Layout>
            <SubPage title='O nás'>
                <div className={s.container}>
                    <h2 className={s.subtitle}>
                        OPTICAL Slovakia – distribútor optických a
                        oftalmologických zariadení.
                    </h2>

                    <p className={s.secondaryText}>
                        Vitajte na stránke spoločnosti OPTICAL Slovakia. Sme
                        jedným z distribútorov špičkových oftalmologických a
                        optických zariadení značky NIDEK na Slovensku. Na trhu
                        pôsobíme už viac ako 13 rokov. Zabezpečujeme predaj,
                        inštaláciu a servis brúsnych automatov značky NIDEK.
                    </p>

                    <h2 className={s.subtitle}>Kvalitné optické vybavenie</h2>

                    <p className={s.secondaryText}>
                        Ponúkame aj vysoko kvalitné optické zariadenia a
                        zariadenia na testovanie refrakčných chýb. Naša široká
                        škála optických zariadení zahŕňa : autorefraktometre,
                        centroskopy, foroptre, projektory optotypu a samozrejme
                        brúsne systémy na spracovanie šošoviek.
                    </p>

                    <h2 className={s.subtitle}>
                        Vyšetrovacie jednotky a Oftalmologické stolíky{' '}
                    </h2>

                    <p className={s.secondaryText}>
                        Našu ponuku dopĺňajú ďalšie typy zariadení pre
                        oftalmologické a optometrické spoločnosti. Už niekoľko
                        rokov vyrábame a ponúkame na treh oftalmologické stolíky
                        pre jedno,alebo dve zariadenia. Nájdete v sekcii:
                        Produkty. Taktiež sme ponuku rozšírili o vyšetrovacie
                        jednotky vlastnej výroby, vhodne do každej modernej
                        prevádzky. Taktiež v sekcii{' '}
                        <Link to='/produkty' className={s.link}>
                            Produkty
                        </Link>
                    </p>
                </div>
            </SubPage>
        </Layout>
    );
};

export default AboutUsPage;
