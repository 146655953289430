import React from 'react';
import s from './ContactPage.module.scss';
import Layout from 'components/Layout/Layout';
import SocialMediaLinks from 'components/SocialMediaLinks/SocialMediaLinks';
import SubPage from 'components/SubPage/SubPage';

type Props = {};

const Contact = (props: Props) => {
    return (
        <Layout>
            <SubPage title={'Kontakt'}>
                <div className={s.container}>
                    <iframe
                        className={s.map}
                        title='location-map'
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1552.8431542028754!2d18.451054257831043!3d49.11398961173564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47148bf081153013%3A0x830952142f878fac!2sZariadenie%20pre%20seniorov%20Katka!5e0!3m2!1spl!2spl!4v1653508752971!5m2!1spl!2spl'
                    />

                    <ul className={s.contactList}>
                        <h2 className={s.contactListHeader}>
                            Prosím neváhajte nás kontaktovať!
                        </h2>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>
                                Adresa sídla spoločnosti
                            </h3>
                            <p className={s.contactItemDescription}>
                                DEDOVEC 1825/343, 017 01 Považská Bystrica{' '}
                            </p>
                        </li>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>
                                Adresa kancelárie spoločnosti
                            </h3>
                            <p className={s.contactItemDescription}>
                                Kuzmányho 903/3 , 017 01 Považská Bystrica
                            </p>
                        </li>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>Telefón </h3>
                            <p className={s.contactItemDescription}>
                                Kancelária: 00421 911 432 093
                            </p>
                            <p className={s.contactItemDescription}>
                                Technik: 00420 733 661 621
                            </p>
                        </li>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>E-mail</h3>
                            <p className={s.contactItemDescription}>
                                office@optical.sk
                            </p>
                        </li>

                        <li className={s.contactListItem}>
                            <h3 className={s.contactItemTitle}>Social Media</h3>
                            <SocialMediaLinks color='black' />
                        </li>
                    </ul>
                </div>
            </SubPage>
        </Layout>
    );
};

export default Contact;
