import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { IServiceArticle } from 'types/types';
import s from './ServiceArticlesItem.module.scss';
import dayjs from 'dayjs';
import { Chip, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faArrowRightLong, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import BeanButton from 'components/BeanButton/BeanButton';

interface Props {
    article: IServiceArticle;
}

const ServiceArticlesItem = ({ article }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        if (location.pathname.includes('blog')) navigate(`${article.id}`);
        else navigate(`blog/${article.id}`);
    };

    return (
        <li className={[s.container, article.attributes.image.data ? '' : s.noImage].join(' ')}>
            <div className={s.top}>
                <h4 className={s.title} onClick={handleClick}>
                    {article.attributes.title}
                </h4>
                <div className={s.date}>
                    <Chip
                        label={dayjs(article.attributes.createdAt).format('DD.MM.YYYY')}
                        icon={<FontAwesomeIcon icon={faCalendarDays} size='lg' />}
                        sx={{ padding: '.5rem' }}
                    />
                </div>
            </div>
            <p className={s.description}>{article.attributes.description}</p>
            <BeanButton
                variant='contained'
                color='black'
                iconEnd={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                link={`${article.id}`}
                text='Čítaj viac'
                fullWidth
            />
        </li>
    );
};

export default ServiceArticlesItem;
