import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import Loading from 'components/Loading/Loading';
import React from 'react';
import { IEvent } from 'types/types';
import s from './EventsList.module.scss';
import EventsListItem from './EventsListItem/EventsListItem';

type Props = {};

const GET_EVENTS = gql`
    query GetEvents {
        events(pagination: { limit: -1 }) {
            data {
                id
                attributes {
                    title
                    description
                    date_start
                    date_end
                    image {
                        data {
                            attributes {
                                url
                                alternativeText
                            }
                        }
                    }
                }
            }
        }
    }
`;

const EventsList = (props: Props) => {
    const { loading, error, data } = useQuery(GET_EVENTS);
    const events: IEvent[] = data?.events.data;

    if (loading) {
        return <Loading />;
    }

    if (!loading && !events) {
        return <h2 className={s.noEvents}>Žiadne nové udalosti</h2>;
    }

    return (
        <ul className={s.list}>
            {events
                .slice()
                .reverse()
                .map((event: IEvent) => (
                    <EventsListItem event={event} key={event.id} />
                ))}
        </ul>
    );
};

export default EventsList;
