import React from 'react';
import s from './SocialMediaLinks.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons';

interface Props {
    color?: 'black' | 'white';
}

const SocialMediaLinks = ({ color }: Props) => {
    return (
        <ul className={s.socialMediaLinks}>
            <a
                href='https://www.facebook.com/PolandOptical'
                target='_blank'
                className={[
                    s.link,
                    ...[
                        color === 'black' && s.black,
                        color === 'white' && s.white,
                    ],
                ].join(' ')}
            >
                <FontAwesomeIcon icon={faFacebook} className={s.icon} />
            </a>
            <a
                href='https://www.instagram.com/polandoptical'
                target='_blank'
                className={[
                    s.link,
                    ...[
                        color === 'black' && s.black,
                        color === 'white' && s.white,
                    ],
                ].join(' ')}
            >
                <FontAwesomeIcon icon={faInstagram} className={s.icon} />
            </a>
            <a
                href='https://www.youtube.com/channel/UCmlbqDMAhKqvNgUsUPQvGIw'
                target='_blank'
                className={[
                    s.link,
                    ...[
                        color === 'black' && s.black,
                        color === 'white' && s.white,
                    ],
                ].join(' ')}
            >
                <FontAwesomeIcon icon={faYoutube} className={s.icon} />
            </a>
        </ul>
    );
};

export default SocialMediaLinks;
