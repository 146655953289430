import React from 'react';
import s from './OfferCard.module.scss';
import { Link } from 'react-router-dom';

interface Props {
    image: string;
    imageAlt?: string;
    title: string;
    link: string;
    borderPostion: 'left' | 'right' | 'center';
    borderColor: 'dark' | 'light';
}

const OfferCard = ({ image, imageAlt, title, link, borderPostion, borderColor }: Props) => {
    return (
        <Link to={link} className={`${s.offerCard} ${s[borderPostion]} ${s[borderColor]}`}>
            <div className={s.content}>
                <img src={image} alt={imageAlt} className={s.image} />
                <h3 className={s.title}>{title}</h3>
            </div>
        </Link>
    );
};

export default OfferCard;
