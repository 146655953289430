import React from 'react';
import './App.scss';

import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import apolloClient from '../apollo.config.js';

// mui
import { ThemeProvider } from '@mui/material/styles';
import theme from 'mui/theme';

//  Pages
import HomePage from 'pages/HomePage/HomePage';
import Contact from 'pages/ContactPage/ContactPage';
import ProductsPage from 'pages/ProductsPage/ProductsPage';
import NotFound from 'pages/NotFoundPage/NotFoundPage';
import ServicePage from 'pages/ServicePage/ServicePage';
import AboutUsPage from 'pages/AboutUsPage/AboutUsPage';

// Components
import ScrollTop from 'components/ScrollTop/ScrollTop';
import EventsPage from 'pages/EventsPage/EventsPage';
import FinancePage from 'pages/FinancePage/FinancePage';
import SpecialOffersPage from 'pages/SpecialOffersPage/SpecialOffersPage';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <ApolloProvider client={apolloClient}>
                    <div className='app'>
                        <ScrollTop>
                            <Routes>
                                <Route index element={<HomePage />} />
                                <Route path='/produkty/*' element={<ProductsPage />} />
                                <Route path='/kontakt' element={<Contact />} />
                                <Route path='/servis/*' element={<ServicePage />} />
                                <Route path='/o-nas' element={<AboutUsPage />} />
                                <Route path='/aktuality/*' element={<EventsPage />} />
                                <Route path='propagacne-akcie' element={<SpecialOffersPage />} />
                                <Route path='/financovanie' element={<FinancePage />} />
                                <Route path='*' element={<NotFound />} />
                            </Routes>
                        </ScrollTop>
                    </div>
                </ApolloProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
